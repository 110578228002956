
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import BaseGridLayout from './layouts/BaseGridLayout.vue'
import BaseHeadline from './base/BaseHeadline.vue'
import { ILink } from '../shared/general/interfaces/ILink'
import { ILocationContactData } from '../shared/general/services/location/LocationContactService'
import ILocationContactInformationStructuredData from '../shared/ILocationContactInformationStructuredData'
import sanitizeJSON from '../shared/general/services/SanitizeJSONService'
import { globalLabelAsString } from '../shared/general/services/StoreService'

@Component({
  name: 'LocationContactInformation',
  components: {
    BaseHeadline,
    BaseGridLayout,
    BaseIconText: () => import('./base/BaseIconText.vue'), // used in component :is
    BaseLink: () => import('./base/BaseLink.vue'), // used in component :is
    RichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class LocationContactInformation extends Vue {
  @Prop({ default: () => [] }) address! : string[]

  @Prop() routeLink ?: ILink

  @Prop({ default: () => [] }) contactData! : ILocationContactData[]

  @Prop({ default: () => [] }) currentInformation! : RichTextElement[]

  @Prop({ required: true }) structuredData! : ILocationContactInformationStructuredData

  private get structuredDataStr () : string {
    return sanitizeJSON(JSON.stringify(this.structuredData))
  }

  private get headline () : string {
    return globalLabelAsString('location_contact_details_headline')
  }

  private get threeTeasers () : boolean {
    return !!this.address.length && !!this.contactData.length && !!this.currentInformation.length
  }
}
